<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row px-3">
      <div class="col-xl-6 mt-2 col-md-6 col-sm-12" >
        <b-embed
          class="shadow"
          type="iframe"
          style="border-radius: 10px; "
          :src="'/maps/map.html?'+q1"
          allowfullscreen
        ></b-embed>
      </div>
      <div class="col-xl-6 mt-2 col-md-6 col-sm-12">
        <b-embed
          class="shadow"
          type="iframe"
          style="border-radius: 10px;"
          :src="'/maps/map.html?'+q2"
          allowfullscreen
        ></b-embed>
      </div>
    </div>
    <div class="row px-3 mt-2">
      <div class="col-xl-6 col-md-6 col-sm-12" >
        <b-embed
          class="shadow"
          type="iframe"
          style="border-radius: 10px; "
          :src="'/maps/map.html?'+q3"
          allowfullscreen
        ></b-embed>
      </div>
      <div class="col-xl-6 col-md-6 col-sm-12">
        <b-embed
          class="shadow"
          type="iframe"
          style="border-radius: 10px; "
          :src="'/maps/map.html?'+q4"
          allowfullscreen
        ></b-embed>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "dashboard",
  data() {
    return {
      q1: '',
      q2: '',
      q3: '',
      q4: ''
    }
  },
  components: {
    
  },
  beforeMount(){
    this.q1 = localStorage.getItem('Q1');
    this.q2 = localStorage.getItem('Q2');
    this.q3 = localStorage.getItem('Q3');
    this.q4 = localStorage.getItem('Q4');
    console.log(
      'Q1: ',this.q1,' ','Q2: ',this.q2,' ','Q3: ',this.q3,' ','Q4: ',this.q4);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  }
};
</script>
